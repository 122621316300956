<template>
  <div class="talent-cultivation-table">
    <h1>数智化动态人才培养一览表</h1>
    <div class="header-container">
      <div class="header-section">
        <h2>基本信息</h2>
        <div class="input-group">
          <label for="school">院校：</label>
          <input
            class="input"
            id="school"
            v-model="basicInfo.university"
            type="text"
            placeholder="请输入院校名称"
          />
        </div>
        <div class="input-group">
          <label for="college">学院：</label>
          <input
            class="input"
            id="college"
            v-model="basicInfo.college"
            type="text"
            placeholder="请输入学院名称"
          />
        </div>
        <div class="input-group">
          <label for="responsible">负责人：</label>
          <input
            class="input"
            id="responsible"
            v-model="basicInfo.head"
            type="text"
            placeholder="请输入负责人姓名"
          />
        </div>
        <div class="input-group">
          <label for="responsible">专业群：</label>
          <input
            class="input"
            id="professionalGroup"
            v-model="basicInfo.professionalGroup"
            type="text"
            placeholder="请输入专业群"
          />
        </div>
      </div>
      <div class="header-section">
        <h2>总目标</h2>
        <ul>
          <el-checkbox-group v-model="goal">
            <el-checkbox :label="1">
              "双高"丨中国特色高水平高职学校和专业（群）建设计划
            </el-checkbox>
            <el-checkbox :label="2">
              "升本"丨本科层次高等学院院校转型
            </el-checkbox>
          </el-checkbox-group>
        </ul>
      </div>
    </div>

    <h2>核心目标及落地措施</h2>
    <table :class="isMobile() ? 'mobile' : 'pc'">
      <thead>
        <tr>
          <th>目标</th>
          <th>关键目标</th>
          <th>目前现状</th>
          <th>落地措施</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(category, categoryIndex) in coreObjectives">
          <tr
            v-for="(objective, objectiveIndex) in category.objectives"
            :key="`${categoryIndex}-${objectiveIndex}`"
          >
            <td
              v-if="objectiveIndex === 0"
              :rowspan="category.objectives.length"
            >
              {{ category.name }}
            </td>
            <td>{{ objective.name }}</td>
            <td>
              <div class="radio-group">
                <span
                  v-for="i in 5"
                  :key="i"
                  class="block"
                  :class="{ 'select-progress': i <= objective.radioValue }"
                  @click="select(categoryIndex, objectiveIndex, i)"
                ></span>
                <!-- <label>
                  <input type="radio" v-model="objective.radioValue" :name="`solved-${categoryIndex}-${objectiveIndex}`"
                    value="是"> 是
                </label>
                <label>
                  <input type="radio" v-model="objective.radioValue" :name="`solved-${categoryIndex}-${objectiveIndex}`"
                    value="否"> 否
                </label> -->
              </div>
            </td>
            <td
              v-if="objectiveIndex === 0"
              :rowspan="category.objectives.length"
            >
              <ul>
                <li
                  v-for="(measure, measureIndex) in category.measures"
                  :key="measureIndex"
                >
                  {{ measure }}
                </li>
              </ul>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <h2>其他</h2>
    <textarea id v-model.trim="remark" name cols="30" rows="10" />
    <div class="submit">
      <span
        class="btn"
        @click="submit"
        :style="
          hasEmptyBasicInfo && hasSolvedFalse && hasOverallGoals
            ? 'opacity: 1;'
            : ' opacity: 0.3;'
        "
      >
        提交
      </span>
    </div>
  </div>
</template>

<script>
import { talentCultivationTable } from '@/api/talentCultivationTable'
export default {
  name: 'TalentCultivationTable',
  data() {
    return {
      basicInfo: {
        university: '',
        college: '',
        head: '',
        professionalGroup: ''
      },
      remark: '',
      overallGoals: [
        '"双高"丨中国特色高水平高职学校和专业（群）建设计划',
        '"升本"丨本科层次高等学院院校转型'
      ],
      goal: [],
      coreObjectives: [
        {
          name: '高水平专业群建设',
          objectives: [
            { name: '优化专业设置', solved: '否', radioValue: '' },
            { name: '提升培养质量', solved: '否', radioValue: '' },
            { name: '促进产教融合', solved: '否', radioValue: '' },
            { name: '增强科研能力', solved: '否', radioValue: '' }
          ],
          measures: [
            'AI专属大模型中控大脑',
            '垂类动态产业动态大模型',
            '产业-岗位-能力图谱数据基座',
            'AI+ 动态人才培养全链条平台',
            '数智化专业群/课程群建设',
            'AI+ 专业群教学管监测实施站点',
            'AI+ 专业群持续优化教研工作坊',
            '基于动态行业岗位的PBL实践应用'
          ]
        },
        {
          name: '双师型教师队伍建设',
          objectives: [
            { name: '提升教师实践能力', solved: '否', radioValue: '' },
            { name: '优化师资结构', solved: '否', radioValue: '' },
            { name: '促进持续培训与发展', solved: '否', radioValue: '' },
            { name: '增强教学质量', solved: '否', radioValue: '' }
          ],
          measures: [
            '产教融合 x 引才育人图谱数据基座',
            '师范院校主体映射多元培养模型',
            '数智化新形态教学示范平台',
            'AI +"双师型"导向教师考核评价',
            'AI+ 教研团队工作坊'
          ]
        },
        {
          name: '产教融合与校企合作',
          objectives: [
            { name: '建立稳定的校企合作机制', solved: '否', radioValue: '' },
            { name: '提升学生实践能力', solved: '否', radioValue: '' },
            { name: '优化人才培养方案', solved: '否', radioValue: '' },
            { name: '推动科研与企业需求对接', solved: '否', radioValue: '' }
          ],
          measures: [
            'AI专属大模型中控大脑',
            '垂类动态产业动态大模型',
            '产业-岗位-能力图谱数据基座',
            'AI+ 动态人才培养全链条平台'
          ]
        },
        {
          name: '创新人才培养模式',
          objectives: [
            { name: '培养创新能力', solved: '否', radioValue: '' },
            { name: '促进个性化学习', solved: '否', radioValue: '' },
            { name: '强化实践教学', solved: '否', radioValue: '' },
            { name: '鼓励跨学科合作', solved: '否', radioValue: '' }
          ],
          measures: [
            'AI+ 项目制学习实践平台',
            'AI专属大模型中控大脑',
            '垂类动态产业动态大模型',
            '产业 x 动态人才培养模型',
            '产业-岗位-能力图谱数据基座',
            'AI+ 动态人才培养全链条平台'
          ]
        },
        {
          name: '信息化建设',
          objectives: [
            { name: '提升教学质量', solved: '否', radioValue: '' },
            { name: '实现在线教育', solved: '否', radioValue: '' },
            { name: '完善管理系统', solved: '否', radioValue: '' },
            { name: '促进数据驱动决策', solved: '否', radioValue: '' }
          ],
          measures: [
            '新形态数智化课程建设',
            'AI技术赋能教学研',
            'AI+ 教学管理全景监测干预',
            'AI专属大模型中控大脑'
          ]
        },
        {
          name: '国际交流与合作',
          objectives: [
            { name: '拓展国际视野', solved: '否', radioValue: '' },
            { name: '引进优质教育资源', solved: '否', radioValue: '' },
            { name: '开展合作项目', solved: '否', radioValue: '' },
            { name: '培养国际化人才', solved: '否', radioValue: '' }
          ],
          measures: ['AI专属大模型中控大脑', '个性化自适应学习开放学习路径']
        },
        {
          name: '职业技能大赛',
          objectives: [
            { name: '提升学生实践能力', solved: '否', radioValue: '' },
            { name: '促进校企合作', solved: '否', radioValue: '' },
            { name: '推动课程改革', solved: '否', radioValue: '' },
            { name: '展示专业实力', solved: '否', radioValue: '' }
          ],
          measures: [
            'AI+ 新形态课程教学管平台',
            'AI+ 项目制学习实践平台',
            'AI专属大模型中控大脑'
          ]
        },
        {
          name: '社会服务能力提升',
          objectives: [
            { name: '增强社会责任感', solved: '否', radioValue: '' },
            { name: '拓展服务领域', solved: '否', radioValue: '' },
            { name: '提升实践能力', solved: '否', radioValue: '' },
            { name: '加强校企合作', solved: '否', radioValue: '' }
          ],
          measures: [
            '产业-岗位-能力图谱数据基座',
            'AI+ 动态人才培养全链条平台',
            '数智化专业群/课程群建设',
            '个性化自适应职业探索平台',
            '项目制学习PBL与实践平台'
          ]
        },
        {
          name: '学生就业与创业支持',
          objectives: [
            { name: '提升就业率', solved: '否', radioValue: '' },
            { name: '增强就业能力', solved: '否', radioValue: '' },
            { name: '鼓励创业精神', solved: '否', radioValue: '' },
            { name: '建立就业服务平台', solved: '否', radioValue: '' }
          ],
          measures: [
            '产业-岗位-能力图谱数据基座',
            'AI+ 动态人才培养全链条平台',
            '数智化专业群/课程群建设',
            '个性化自适应职业探索平台',
            '项目制学习PBL与实践平台'
          ]
        }
      ],
      hasEmptyBasicInfo: false,
      hasSolvedFalse: false,
      hasOverallGoals: false
    }
  },
  watch: {
    basicInfo: {
      handler() {
        if (Object.values(this.basicInfo).some((value) => value === '')) {
          this.hasEmptyBasicInfo = false
        } else {
          this.hasEmptyBasicInfo = true
        }
      },
      deep: true,
      immediate: true
    },
    coreObjectives: {
      handler() {
        console.log(this.goal)
        const transformedForms = this.transformCoreObjectives()
        if (transformedForms.some((form) => form.value === '')) {
          this.hasSolvedFalse = false
        } else {
          this.hasSolvedFalse = true
        }
      },
      deep: true,
      immediate: true
    },
    goal: {
      handler() {
        if (this.goal.length == 0) {
          this.hasOverallGoals = false
        } else {
          this.hasOverallGoals = true
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    select(categoryIndex, objectiveIndex, num) {
      this.coreObjectives[categoryIndex].objectives[objectiveIndex].radioValue =
        num
    },

    transformCoreObjectives() {
      const forms = []
      this.coreObjectives.forEach((category, catIndex) => {
        category.objectives.forEach((objective, objIndex) => {
          const serial = catIndex * category.objectives.length + objIndex + 1
          const value = objective.radioValue
          forms.push({ serial, value })
        })
      })
      return forms
    },
    submit() {
      const transformedForms = this.transformCoreObjectives()
      if (
        !this.hasEmptyBasicInfo ||
        !this.hasSolvedFalse ||
        !this.hasOverallGoals
      ) {
        return false
      }
      this.isSubmit = true
      let formData = {
        ...this.basicInfo,
        forms: transformedForms,
        remark: this.remark,
        goal: this.goal
      }
      talentCultivationTable(formData).then((res) => {
        if (res.data.success) {
          this.$message.closeAll()
          this.$message({ message: '提交成功', type: 'success' })
          this.clearData()
        }
      })
    },
    clearData() {
      this.basicInfo = {
        university: '',
        college: '',
        head: '',
        professionalGroup: ''
      }
      this.remark = ''
      this.coreObjectives.forEach((category) => {
        category.objectives.forEach((objective) => {
          objective.radioValue = ''
        })
      })
      this.goal = []
      this.hasEmptyBasicInfo = false
      this.hasSolvedFalse = false
      this.hasOverallGoals = false
    },
    isMobile() {
      return (
        typeof window.orientation !== 'undefined' ||
        navigator.userAgent.indexOf('IEMobile') !== -1
      )
    }
  }
}
</script>

<style scoped>
.el-checkbox-group {
  display: flex;
  flex-flow: column;
}

.el-checkbox {
  height: 50px;
}

.talent-cultivation-table {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 50px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.header-section {
  width: 48%;
}

.input-group {
  margin-bottom: 10px;
}

.input-group label {
  display: inline-block;
  width: 70px;
  font-weight: bold;
}

.input-group input {
  width: calc(100% - 80px);
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

table {
  border-collapse: collapse;
  margin-bottom: 20px;
}
.mobile {
  width: 400px;
}
.pc {
  width: 100%;
}
th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}
th:nth-child(3),
td:nth-child(3) {
  width: 25%;
  min-width: 200px;
}
h1,
h2 {
  color: #333;
}

h1 {
  text-align: center;
  margin-bottom: 40px;
}

h2 {
  margin-bottom: 20px;
}

ul {
  margin: 0;
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
}

.input {
  border-radius: 20px !important;
  padding: 10px !important;
  width: 300px !important;
}

.radio-group {
  display: flex;
  justify-content: space-around;
}

.block {
  display: flex;
  flex: 1;
  height: 50px;
  background: #f4f7f8;
  margin: 0 5px;
  transition: background-color 0.3s, transform 0.2s;
}

.block:hover {
  transform: scale(1.1);
}

.select-progress {
  background: #8077ec;
  cursor: pointer;
}

@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
  }

  .header-section {
    width: 100%;
  }

  .input-group label {
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }

  .input-group input {
    width: 100%;
  }
}

textarea {
  width: 100%;
  padding: 20px;
}

.submit {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  padding: 13px 50px;
  background: #333;
  color: #fff;
  font-size: 18px;
  border-radius: 10px;
}
</style>
